import AnimatedNumber from "animated-number-vue";

export default {
  name: "card",
  components: {
    AnimatedNumber
  },
  props: {
    item: {
      type: Object,
      default: () => {
      },
    }
  },
  data() {
    return {
      value: parseInt(this.item.cokingTime),
      personCount:parseInt(this.item.numberOfPersons),
      duration: 4000
    }
  },
  created() {

  },
  computed: {},

  methods: {
    formatToTime(value) {
      return value
    },
    formatToPersons(personCount) {
      return personCount
    }

  }
}
