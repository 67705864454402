import {mapActions, mapGetters, mapMutations} from "vuex";
import card from "@/modules/allRecipe/card/index.vue";
export default {
  name: "all-recipe",
  components: {
    card
  },
  data() {
    return {}
  },
  created() {
    this.getRecipe()
  },
  computed: {
    ...mapGetters({
      recipes: 'receipt/recipes'
    })
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({
      getRecipe: 'receipt/GET_ALL_RECEIPT'
    }),
  }
}
